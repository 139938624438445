'use client';

import { FC } from 'react';

import { Button, ButtonProps } from '@mui/material';

import { Locale } from 'common/i18n/i18n.config';
import { Editor } from 'common/routes';

interface Props extends ButtonProps {
  lang: Locale;
}

export const EditorButton: FC<Props> = ({ children, lang, ...props }) => (
  <Button component={Editor.Link} lang={lang} {...props}>
    {children}
  </Button>
);
