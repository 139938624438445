'use client';

import { Box, Typography } from '@mui/material';
import { usePathname } from 'next/navigation';

import { PageContainer } from 'common/components/PageContainer/PageContainer';
import { getGlobalTranslate } from 'common/i18n/globalTranslate';
import { Locale } from 'common/i18n/i18n.config';
import { ToolsVideoCompressor, ToolsVideoTrimmer } from 'common/routes';

import { Item } from './Item';

interface Props {
  lang: Locale;
}

export const HeroDiscoverTools = async ({ lang }: Props) => {
  const pathname = usePathname();
  const { Labels, Navigation } = await getGlobalTranslate(lang);

  if (!Labels.DiscoverVidemixVideoTools) {
    return null;
  }

  return (
    <Box>
      <Typography sx={{ mb: 4, textAlign: 'center' }} variant="h2">
        {Labels.DiscoverVidemixVideoTools}
      </Typography>
      <PageContainer sx={{ display: 'flex', justifyContent: 'center', pb: 10, pt: 5 }}>
        <Item
          image="/images/icons/video-trimmer-icon_90x90.svg"
          isActive={ToolsVideoTrimmer.isActive(lang, pathname)}
          label={Navigation.VideoTrimmer}
          lang={lang}
          Link={ToolsVideoTrimmer.Link}
        />
        <Item
          image="/images/icons/video-compressor-icon_90x90.svg"
          isActive={ToolsVideoCompressor.isActive(lang, pathname)}
          label={Navigation.VideoCompressor}
          lang={lang}
          Link={ToolsVideoCompressor.Link}
        />
      </PageContainer>
    </Box>
  );
};
