import { FC } from 'react';

import { Box } from '@mui/material';

import { PageContainer } from 'common/components/PageContainer/PageContainer';

import { HeroProps } from './HeroProps';

export const Hero: FC<HeroProps> = ({ header, leftNode, rightNode, ...props }) => (
  <Box
    {...props}
    sx={{
      pb: 14,
      pt: 14,
      ...(props.sx ?? {})
    }}
  >
    <PageContainer sx={{ textAlign: { md: 'initial', xs: 'center' } }}>
      {header && <Box sx={{ mb: 4, textAlign: 'center' }}>{header}</Box>}
      <Box sx={{ display: { md: 'flex', xs: 'block' } }}>
        <Box
          sx={{
            alignContent: 'center',
            display: { md: 'block', xs: 'flex' },
            flex: 1,
            justifyContent: 'center',
            mb: { md: 0, xs: 3 }
          }}
        >
          {leftNode}
        </Box>
        <Box
          sx={{
            alignContent: 'center',
            display: { md: 'block', xs: 'flex' },
            flex: 1,
            justifyContent: 'center'
          }}
        >
          {rightNode}
        </Box>
      </Box>
    </PageContainer>
  </Box>
);
