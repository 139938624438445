'use client';

import { FC } from 'react';

import { Button } from '@mui/material';

import { signOut } from './actions';

export const SignOutButton: FC = () => {
  const handleClick = async () => {
    await signOut();
  };

  return (
    <Button sx={{ mr: 1 }} variant="outlined" onClick={handleClick}>
      Sign Out
    </Button>
  );
};
