import { FC, useCallback, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List } from '@mui/material';

import { VideoFile } from 'common/pages/EditorPage/models/types';

import { UploadFileItem } from './UploadFileItem';

interface Props {
  onClose: () => void;
  videoFiles: VideoFile[];
}

export const UploadingFilesModal: FC<Props> = ({ onClose, videoFiles }) => {
  const [open, setOpen] = useState(!!videoFiles.length);
  console.log('open', open);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [onClose]);

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      maxWidth="sm"
      open={open && !!videoFiles.length}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">Uploading files</DialogTitle>
      <DialogContent>
        <List>
          {videoFiles.map(videoFile => (
            <UploadFileItem key={videoFile.file.name} videoFile={videoFile} />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
