import * as React from 'react';
import { FC, PropsWithChildren } from 'react';

import { Container } from '@mui/material';
import { ContainerProps } from '@mui/material/Container/Container';

interface Props extends PropsWithChildren, ContainerProps {}

export const PageContainer: FC<Props> = ({ children, ...props }) => (
  <Container maxWidth={props.maxWidth || 'lg'} {...props}>
    {children}
  </Container>
);
