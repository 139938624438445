import { FC } from 'react';

import { CircularProgress, CircularProgressProps } from '@mui/material';

import { useSvgHelper } from '../Icons/useSvgHelper';

export const GradientCircularProgress: FC<CircularProgressProps> = props => {
  const { getId, getUrl } = useSvgHelper('GradientCircularProgress');

  return (
    <>
      <svg height={0} width={0}>
        <defs>
          <linearGradient id={getId('a')} x1="0%" x2="0%" y1="0%" y2="100%">
            <stop offset="0%" stopColor="#e01cd5" />
            <stop offset="100%" stopColor="#1CB5E0" />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress sx={{ 'svg circle': { stroke: getUrl('a') } }} {...props} />
    </>
  );
};
