import { FC } from 'react';

import { LinkProps } from 'common/components/Link';
import { Locale } from 'common/i18n/i18n.config';

import { Content } from './Content';

interface Props {
  Link: FC<Omit<LinkProps, 'href'>>;
  image: string;
  isActive: boolean;
  label: string;
  lang: Locale;
}

export const Item: FC<Props> = ({ image, isActive, label, lang, Link }) => {
  if (!label) {
    return null;
  }

  const content = <Content image={image} isActive={isActive} label={label} />;

  return isActive ? (
    <>{content}</>
  ) : (
    <Link lang={lang} style={{ textDecoration: 'none' }}>
      {content}
    </Link>
  );
};
