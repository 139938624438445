import { getCookie } from 'cookies-next';

import { TOKEN_COOKIE_KEY } from '../const/cookies.const';
import { Optional } from '../lib/types/assert';
import { decodeJwtToken, isValidJwt } from '../services/jwtTokens.service';

export const processToken = (token: Optional<string>) => {
  const jwt = token ? decodeJwtToken(token) : null;

  return {
    isAuthenticated: jwt ? isValidJwt(jwt) : null,
    jwt,
    token
  };
};
export const getAuth = () => {
  const token = getCookie(TOKEN_COOKIE_KEY);

  return processToken(token);
};
