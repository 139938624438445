'use client';

import { FC } from 'react';

import { Button, ButtonProps } from '@mui/material';

import { GOOGLE_AUTH_URL } from 'common/const/api.const';

export const AuthButton: FC<ButtonProps> = ({ children, onClick, ...props }) => (
  <Button
    onClick={e => {
      console.log('Log in', GOOGLE_AUTH_URL);
      onClick?.(e);
      window.location.href = GOOGLE_AUTH_URL;
    }}
    {...props}
  >
    {children}
  </Button>
);
