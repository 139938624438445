// Automatically generated by declarative-routing, do NOT edit
import { z } from 'zod';

import { makeRoute } from './makeRoute';
import { PATHS } from './PATHS';

const defaultInfo = {
  params: z.object({
    lang: z.string()
  }),
  search: z.object({}).optional()
};

export const Home = makeRoute(
  '/',
  {
    ...defaultInfo,
    name: 'Home'
  },
  PATHS.Home
);

export const Editor = makeRoute(
  '/editor',
  {
    ...defaultInfo,
    name: 'Editor'
  },
  PATHS.Editor
);

export const PrivacyPolicy = makeRoute(
  '/privacy-policy',
  {
    ...defaultInfo,
    name: 'PrivacyPolicy'
  },
  PATHS.PrivacyPolicy
);

export const TermsOfUse = makeRoute(
  '/terms-of-use',
  {
    ...defaultInfo,
    name: 'TermsOfUse'
  },
  PATHS.TermsOfUse
);

export const Tools = makeRoute(
  '/tools',
  {
    ...defaultInfo,
    name: 'Tools'
  },
  PATHS.Tools
);

export const ToolsVideoTrimmer = makeRoute(
  '/tools/video-trimmer',
  {
    ...defaultInfo,
    name: 'ToolsVideoTrimmer'
  },
  PATHS.VideoTrimmer
);

export const ToolsVideoCompressor = makeRoute(
  '/tools/video-compressor',
  {
    ...defaultInfo,
    name: 'ToolsVideoCompressor'
  },
  PATHS.VideoCompressor
);
