'use client';

import * as React from 'react';
import { FC } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { usePathname } from 'next/navigation';

import { LogoSvg } from 'common/components/Icons/LogoSvg';
import { getGlobalTranslate } from 'common/i18n/globalTranslate';
import { Locale } from 'common/i18n/i18n.config';
import { Tools, ToolsVideoCompressor, ToolsVideoTrimmer } from 'common/routes';
import { BORDER, COLOR } from 'common/styles/colors';

import { AuthMenu } from './components/AuthMenu';
import { MobileMenu } from './components/MobileMenu';

interface Props {
  readonly lang: Locale;
}

export const TopAppBar: FC<Props> = async ({ lang }) => {
  // TODO: 1
  const pathname = usePathname();
  const dictionary = await getGlobalTranslate(lang);
  const { Navigation, Videmix } = dictionary;

  return (
    <AppBar color="inherit" sx={{ borderBottom: BORDER, boxShadow: 'none', zIndex: 10000 }}>
      <Container component="nav" maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            component={Link}
            href="/"
            lang={lang}
            sx={{
              color: 'inherit',
              display: { md: 'flex', xs: 'none' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              mr: 2,
              textDecoration: 'none',
              textTransform: 'uppercase'
            }}
            title={Videmix}
            variant="h6"
            noWrap
          >
            <LogoSvg />
          </Typography>

          <Box sx={{ display: { md: 'none', xs: 'flex' }, flexGrow: 1 }}>
            <MobileMenu dictionary={dictionary} />
          </Box>

          <Typography
            component={Link}
            href="/"
            lang={lang}
            sx={{
              color: 'inherit',
              display: { md: 'none', xs: 'flex' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              mr: 2,
              textDecoration: 'none',
              textTransform: 'uppercase'
            }}
            title={Videmix}
            variant="h5"
            noWrap
          >
            <LogoSvg />
          </Typography>

          <Box sx={{ display: { md: 'flex', xs: 'none' }, flexGrow: 1 }}>
            <Box sx={{ alignItems: 'center', display: 'flex', m: 2 }}>
              <Typography variant="body1">
                <Tools.Link
                  lang={lang}
                  style={{
                    color: Tools.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
                    textDecoration: 'none'
                  }}
                >
                  {Navigation.Tools}
                </Tools.Link>
              </Typography>
            </Box>

            {Navigation.VideoTrimmer && (
              <Box sx={{ alignItems: 'center', color: 'inherit', display: 'flex', m: 2 }}>
                <Typography variant="body1">
                  <ToolsVideoTrimmer.Link
                    lang={lang}
                    style={{
                      color: ToolsVideoTrimmer.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
                      textDecoration: 'none'
                    }}
                  >
                    {Navigation.VideoTrimmer}
                  </ToolsVideoTrimmer.Link>
                </Typography>
              </Box>
            )}

            {Navigation.VideoCompressor && (
              <Box sx={{ alignItems: 'center', color: 'inherit', display: 'flex', m: 2 }}>
                <Typography variant="body1">
                  <ToolsVideoCompressor.Link
                    lang={lang}
                    style={{
                      color: ToolsVideoCompressor.isActive(lang, pathname) ? COLOR.PRIMARY_COLOR : 'inherit',
                      textDecoration: 'none'
                    }}
                  >
                    {Navigation.VideoCompressor}
                  </ToolsVideoCompressor.Link>
                </Typography>
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', flexGrow: 0 }}>
            <AuthMenu />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
