import { FC, ReactNode } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';

import { GradientCircularProgress } from '../GradientCircularProgress/GradientCircularProgress';

interface Props {
  defaultComponent?: ReactNode;
  doneComponent?: ReactNode;
  errorComponent?: ReactNode;
  isDone: boolean;
  isError: boolean;
  isLoading: boolean;
  loadingComponent?: ReactNode;
}

export const LoadingErrorDoneStatus: FC<Props> = ({
  defaultComponent = null,
  doneComponent = <DoneIcon />,
  errorComponent = <ErrorIcon />,
  isDone,
  isError,
  isLoading,
  loadingComponent = <GradientCircularProgress />
}) => {
  if (isLoading) {
    return loadingComponent;
  }

  if (isError) {
    return errorComponent;
  }

  if (isDone) {
    return doneComponent;
  }

  return defaultComponent;
};
