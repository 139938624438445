import { VIDEO_FILE_UPLOAD_URL } from 'common/const/api.const';

import { VideoFile } from '../pages/EditorPage/models/types';

export interface UploadedFile {
  link: string;
  videoId: string;
}

export const uploadVideoFile = async (videoFile: VideoFile, jwtToken: string): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append('file', videoFile.file);

  const response = await fetch(VIDEO_FILE_UPLOAD_URL, {
    body: formData,
    headers: {
      Authorization: `Bearer ${jwtToken}`
    },
    method: 'POST'
  });

  return response.json();
};
