import { i18n } from 'common/i18n/i18n.config';

import JSON_PATH from '../i18n/PATHS.json';

import { getAll } from './utils';

export const PATHS = {
  Editor: {
    ...getAll('/editor')
  },
  Home: {
    ...getAll('/')
  },
  PrivacyPolicy: {
    ...getAll('/privacy-policy')
  },
  TermsOfUse: {
    ...getAll('/terms-of-use')
  },
  ...JSON_PATH
};

i18n.locales.forEach(key => {
  if (!PATHS.VideoTrimmer[key].startsWith(PATHS.Tools[key])) {
    PATHS.VideoTrimmer[key] = `${PATHS.Tools[key]}${PATHS.VideoTrimmer[key]}`;
  }
  if (!PATHS.VideoCompressor[key].startsWith(PATHS.Tools[key])) {
    PATHS.VideoCompressor[key] = `${PATHS.Tools[key]}${PATHS.VideoCompressor[key]}`;
  }
});
