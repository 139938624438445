'use client';

import { FC } from 'react';

import { Chip } from '@mui/material';

import { Locale } from 'common/i18n/i18n.config';

import { Link } from '../Link';

interface Props {
  href: string;
  isActive: boolean;
  label: string;
  locale: Locale;
}

export const LocaleLink: FC<Props> = ({ href, isActive, label, locale }) => (
  <Chip
    color={isActive ? 'primary' : 'default'}
    component={Link}
    href={href}
    label={label}
    lang={locale}
    size="small"
    sx={{ m: 0.5 }}
    variant={isActive ? 'filled' : 'outlined'}
    clickable
  />
);
