'use client';

import { FC } from 'react';

import { ButtonProps } from '@mui/material';

import { useAuth } from 'common/hooks/useAuth';
import { Locale } from 'common/i18n/i18n.config';

import { AuthButton } from './AuthButton';
import { EditorButton } from './EditorButton';

interface Props extends ButtonProps {
  authTitle: string;
  editorTitle: string;
  lang: Locale;
}

export const AuthEditorButton: FC<Props> = ({ authTitle, editorTitle, lang, variant = 'contained', ...props }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <EditorButton lang={lang} variant={variant} {...props}>
      {editorTitle}
    </EditorButton>
  ) : (
    <AuthButton variant={variant} {...props}>
      {authTitle}
    </AuthButton>
  );
};
