'use client';

import { getCookie } from 'cookies-next';

import { TOKEN_COOKIE_KEY } from 'common/const/cookies.const';

import { processToken } from './getAuth';

export const useAuth = () => {
  const token = getCookie(TOKEN_COOKIE_KEY);

  return processToken(token);
};
