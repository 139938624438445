'use client';

import { useRef } from 'react';

import { uuid } from 'common/lib/uuid';

export const useSvgHelper = (svgName?: string) => {
  const iconId = useRef(svgName ?? uuid());
  const id = iconId.current;

  const getId = (name: string) => `${svgName}_${id}_${name}`;
  const getHash = (name: string) => `#${getId(name)}`;
  const getUrl = (name: string) => `url(${getHash(name)})`;

  return {
    getHash,
    getId,
    getUrl,
    id
  };
};
