import { useCallback, useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { VideoFile } from 'common/pages/EditorPage/models/types';

import { getVideoFile } from './utils';

export const useUploadFiles = () => {
  const [videoFiles, setVideoFiles] = useState<VideoFile[]>([]);

  const onDrop = useCallback(async (raw: File[]) => {
    const _files = await Promise.all(raw.map(getVideoFile));
    setVideoFiles(_files);
  }, []);

  const handleCloseUploadModal = useCallback(() => {
    setVideoFiles([]);
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: {
      'video/*': []
    },
    // @ts-ignore
    onDrop
  });

  return { getInputProps, getRootProps, handleCloseUploadModal, isDragActive, videoFiles };
};
