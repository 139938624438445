import jwt, { JwtPayload } from 'jsonwebtoken';

export const decodeJwtToken = (token: string): JwtPayload | null => {
  try {
    const decoded = jwt.decode(token) as JwtPayload | null;

    if (!decoded) {
      return null;
    }

    return decoded;
  } catch (error) {
    return null;
  }
};

export const isValidJwt = (decoded: JwtPayload): boolean | null => {
  const currentTime = Math.floor(Date.now() / 1000);

  if (decoded.exp && typeof decoded.exp === 'number') {
    if (decoded.exp < currentTime) {
      return false;
    }
  } else {
    return null;
  }

  return true;
};

export const isValidJwtToken = (token: string): boolean | null => {
  const decoded = decodeJwtToken(token);

  if (!decoded) {
    return null;
  }

  return isValidJwt(decoded);
};
