import React, { FC } from 'react';

import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import Image from 'next/image';

interface Props {
  image: string;
  isActive: boolean;
  label: string;
}

export const Content: FC<Props> = ({ image, isActive, label }) => (
  <Card
    sx={{
      ml: 2,
      mr: 2,
      width: 260
    }}
    raised
  >
    <CardActionArea component="div" disabled={isActive} sx={{ pb: 3, pt: 4 }}>
      <CardContent>
        <Typography sx={{ textAlign: 'center' }} gutterBottom>
          <Image alt="" height={90} src={image} width={90} />
        </Typography>
        <Typography
          component="p"
          sx={{
            fontSize: 24,
            fontWeight: isActive ? 'bold' : 'normal',
            mt: 1,
            textAlign: 'center'
          }}
          variant="h4"
          gutterBottom
        >
          {label}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);
